require('./bootstrap');

/*!
 * SCRIPTS DE LA WEB PÚBLICA
 */

const footerImage = document.querySelector('footer .img-footer img');
const footerText = document.querySelector('footer .info-footer');

function imageHeight() {
    if(window.getComputedStyle(footerImage, null).display !== 'none'){
        console.log(footerText.offsetHeight);
        let imgHeight = footerImage.height;
        footerText.style.height = imgHeight + 'px';
        console.log(imgHeight);
    } else {
        footerText.style.height = 'auto';
    }
}

// window.onload = imageHeight;
// window.onresize = imageHeight;
